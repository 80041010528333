<template>
    <div class="misc-wrapper">
        <b-row class="justify-content-center align-items-center" style="height: 100vh;">
            <b-col cols="12" lg="8">
                <b-card class="mt-3 custom-centered-card">
                    <div class="justify-content-center d-flex align-items-center flex-column text-center">
                        <!-- LOGO -->
                        <div class="mb-2">
                            <img class="logo" :src="logoImage" style="height: 20vh; max-height:80px" alt="" />
                        </div>
                        <!-- Título -->
                        <h1>{{ $t('terms.tittle') }}</h1>
                    </div>

                    <div class="justify-content-center d-flex align-items-center text-center" style="margin-top:30px">
                        <p class="text-rows">{{ $t('terms.disclosure') }}</p>
                    </div>
                    <!-- CHECKBOX -->
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="d-flex flex-column">
                            <b-form-checkbox v-model="selected" value="A">
                                {{ $t('terms.accept') }} <a :href="$t('terms.link_privacy')" target="_blank">{{$t('terms.checkbox1') }}</a>
                            </b-form-checkbox>
                            <b-form-checkbox v-model="selected" value="B">
                                {{ $t('terms.accept') }} <a :href="$t('terms.link_legal')" target="_blank">{{$t('terms.checkbox2') }}</a>
                            </b-form-checkbox>
                        </div>
                    </div>
                    <!-- FINAL CHECKBOX -->

                    <!-- BOTONES ACEPTACIÓN -->
                    <div class="d-flex justify-content-center align-items-center mt-3">
                        <b-button class="mr-1" :disabled="!todosLosCheckboxesMarcados" variant="primary" @click="guardarTimestamp">
                            {{ $t('terms.accept_terms') }}
                        </b-button>
                        <b-button  variant="flat-secondary" @click="logout">{{ $t('terms.decline') }}</b-button>
                    </div>
                    <!-- FINAL BOTONES ACEPTACIÓN -->
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import {
    BButton,
    BCard,
    BCardBody,
    BCardTitle,
    BModal,
    BTable,
    BTableLite,
    BRow,
    BCol,
} from 'bootstrap-vue'

import router from '@/router'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { acceptPrivacy } from '@/api/routes'
import { BFormCheckbox } from 'bootstrap-vue'
import { logoImageFunction } from '@/main'


export default {
    computed: {
        todosLosCheckboxesMarcados() {
            return this.selected && this.selected.length === 2;
        },
        logoImage() {
          logoImageFunction();
        },
    },
    components: {
        BButton,
        BCard,
        BCardBody,
        BModal,
        BCardTitle,
        BTable,
        BTableLite,
        BRow,
        BCol,
        BFormCheckbox,
    },
    data() {
        return {
            selected: [],
        }
    },
    methods: {
        async guardarTimestamp() {
            // Llamada al backend
            await this.$http.post(acceptPrivacy, {}).then(response => {
                this.$store.commit('user/setPrivacyAccepted', response.data.timestamp)
                this.$router.push({ name: 'main-home' });
            })
                .catch(error => {
                    console.error('Error al guardar el timestamp:', error);
                });
        },
        logout() {
            // Remove userData from localStorage
            this.$store.commit('user/logout')
            localStorage.clear()

            // Reset ability
            this.$ability.update(initialAbility)

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
        },
    }
}
</script>
<style scoped>
/* Aplicar estilos para centrar el modal vertical y horizontalmente */
.b-modal.is-visible .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-centered-card {
    /* Estilos para centrar vertical y horizontalmente */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text-rows {
    display: flex;
    flex-direction: column;
    height: 100;
    margin-right: 10%;
    margin-left: 10%;
}

.text-rows p {
    flex: 0.5;
}
</style>